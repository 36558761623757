import './TestPage.css'

const TestPage = ({}) => {

    return ( 
        <div className="test-page">
            
        </div>
    );
}
 
export default TestPage;